import React from "react";
import { SubLayout, ContactParticle, Careers } from "../components";
import Seo from "../components/Seo";

const CareersPage = () => {
  return (
    <SubLayout>
      <Seo
        title="採用情報"
        description="医療法人社団慶育会では医師・看護師・サポートスタッフなど多様な職種が揃い、全てのスタッフが一つのチームとして活躍できる組織を目指しています。同じ志をもって取り組んでいただける方々を広く歓迎しております。"
        keywords="医療法人社団 慶育会,グレースメディカルグループ,在宅医療,訪問診療,訪問医療,採用情報,求人,キャリア,リクルート,CAREER,RECRUIT"
      />
      <Careers />
      <ContactParticle />
    </SubLayout>
  );
};

export default CareersPage;
